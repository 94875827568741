<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import SharedForm from '@/components/auth_components/AuthSharedForm';

  export default {
    extends: SharedForm,
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsNotInterpreter',
        'userIsInterpreter',
        'userIsManager',
        'userIsEnterpriseMember',
        'defaultBookingReference',
        'userHasInterpretation',
        'userIsSeller'
      ]),
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData || {}
      }),
      ...mapState('TTManageSupplierSkill', {
        skillsNeedDialect: (state) => state.skillsNeedDialect || []
      }),
      ...mapState('UserInfoStore', {
        userBookedInterpretationJobs: ({additionalUserInfo}) => additionalUserInfo.userBookedInterpretationJobs,
        userBookedTranslationJobs: ({additionalUserInfo}) => additionalUserInfo.userBookedTranslationJobs
      }),
      submitText() { return this.$gettext('Log in'); },
      hrText() { return this.$gettext('or log in with'); }
    },
    methods: {
      ...mapActions('PostInterpretationStore', [
        'createForm',
        'setCookieJobInfo'
      ]),
      postJobAfterSignIn() {
        if (this.userIsNotInterpreter) {
          const storeObj = JSON.parse(this.$cookieManager.getCookie('openAssignInfo'));
          if (this.userIsEnterpriseMember && !storeObj.bookingRef) {
            storeObj.bookingRef = this.defaultBookingReference;
            storeObj.paymentBookingRef = this.defaultBookingReference;
          }
          this.setCookieJobInfo(storeObj);
          this.createForm().then((jobForm) => {
            this.$store.dispatch('postAnAssignment', {jobForm}).then((newJobObj) => {
              this.$cookieManager.setCookie('openAssignInfo', '', {path: '/', expires: 2592000});
              this.$cookieManager.setCookie('addJobAfterSignIn', '', {path: '/', expires: 2592000});
              this.trackSubmitGA(newJobObj.job);
              this.$router.push(this.$makeRoute({name: 'BuyerOneAssignment', params: {id: newJobObj.job.id}}));
            });
          });
        } else {
          this.$store.commit('setOpenAssignModalState', true);
          window.location.reload();
        }
      },
      setSkillsNeedDialect() {
        if (this.skillsNeedDialect?.length) {
          localStorage.setItem('skillsNeedDialect', JSON.stringify(this.skillsNeedDialect));
          this.$store.commit('ModalStore/setModal', {
            component: 'dialects-modal',
          });
        }
      },
      formIsValid() {
        let res = true;
        this.emailErrors = [];
        this.passwordErrors = [];
        this.phoneNumberErrors = [];

        if (this.isPhoneOption) {
          if (!this.phoneNumber || !this.phoneCode) {
            this.phoneNumberErrors.push(this.$gettext('Please enter an phone number'));
            res = false;
          }
        } else {
          if (!this.email) {
            this.emailErrors.push(this.$gettext('Please enter an email'));
            res = false;
          } else if (!this.validateEmail(this.email)) {
            this.emailErrors.push(this.$gettext('Email is not valid'));
            res = false;
          }
        }
        if (!this.password) {
          this.passwordErrors.push(this.$gettext('Please enter a password'));
          res = false;
        } else if (this.password.length < 8) {
          this.passwordErrors.push(this.$gettext('Password is too short (minimum is 8 characters)'));
          res = false;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrorsName, errors: this.phoneNumberErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.passwordErrorsName, errors: this.passwordErrors});

        return res;
      },
      submitForm() {
        if (this.formIsValid()) {
          const form = new FormData();
          const username = this.isPhoneOption ? `${this.phoneCode}${this.phoneNumber}` : this.email.toLowerCase().trim();

          form.append('username', username);
          form.append('password', this.password.trim());

          this.progressActive = true;
          this.$store.dispatch('TTAuthStore/authorize', {form: form, type: 'signIn', rememberMe: this.rememberMe}).then((response) => {
            this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: {uid: response.identity.uid}});
            this.$store.dispatch('UserInfoStore/fetchUserInfo', response.identity.uid).then((data) => {
              this.$store.commit('TTAuthStore/setAuthInfoToStorage', {token: response.token, uid: response.identity.uid});
              if (data.currentProvider && !this.$isOnDomain(data.currentProvider)) {
                this.$store.dispatch('TTAuthStore/logOut').then(() => {
                  window.location.replace(this.getRedirectLink({
                    token: response.token,
                    uid: response.identity.uid
                  }));
                });
                return;
              }
              // Google Tag Manager
              this.trackSignInGA();
              // End Google Tag Manager
              if (this.addJobAfterSignIn) {
                this.postJobAfterSignIn();
              } else {
                if (this.userIsInterpreter) {
                  this.$store.dispatch('TTManageSupplierSkill/checkSkill', {id: this.$store.getters['UserInfoStore/userUid']}).then(() => {
                    this.setSkillsNeedDialect();
                  });
                  this.$router.replace(this.$makeRoute({
                    name: (this.userIsSeller && !this.userHasInterpretation) ? 'ServerAllProjects' : 'ServerHome'
                  }));
                } else if (this.userIsManager) {
                  this.$router.replace(this.$makeRoute({
                    name: 'ManagerAllAssignments',
                    query: {view: 'urgent',
                            page: 1,
                            items: this.$store.state.filterItemsAmount}
                  }));
                } else if (this.userIsNotInterpreter) {
                  if (this.$route.query.interpreterid) {
                    window.location.replace(
                      this.getDirectBookingRedirectLink(this.$route.query.interpreterid)
                    );
                  } else {
                    this.$router.replace(this.$makeRoute({
                      name: 'BuyerHome'
                    }));
                  }
                }
                if (this.$store.state.mobileApp) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }
              }
            }).catch((error) => {
              this.progressActive = false;
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
          }).catch((error) => {
            this.progressActive = false;
            if (error && error.status && error.status === 429) {
              this.emailErrors.push(this.$gettext('Your account has been temporarily paused due to abnormal activity. Please try again later. If this continues to happen, please contact our customer center at post@salita.no'));
              this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
              return;
            }
            if (error && error.errors && error.errors.status === 'banned') {
              const template = this.$gettext('Your account is blocked. Please send an email to <a class="sk-link sk-link--default" href="mailto:%{email}">%{email}</a> for assistance');
              const messageText = this.$gettextInterpolate(template, {email: this.$getString('domainData', 'supportEmail')});
              this.$store.commit('InfoModalStore/setInfoModal', {text: messageText});
            } else if (error && error.errors && error.errors.status === 'inactive') {
              const template = this.$gettext('Your account has been deactivated so you may not log in or use it. If you don\'t know why it\'s inactive please contact support <a class="sk-link sk-link--default" href="mailto:%{email}">%{email}</a>.');
              const messageText = this.$gettextInterpolate(template, {email: this.$getString('domainData', 'supportEmail')});
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: messageText
                });
              });
            } else if (error && error.errors && error.errors.account === 'suppressed') {
              const template = this.$gettext('Please contact our support team to activate your account.<br> Please send an email to <a class="sk-link sk-link--default" href="mailto:%{email}">%{email}</a> for assistance');
              const messageText = this.$gettextInterpolate(template, {email: this.$getString('domainData', 'supportEmail')});
              this.$store.commit('InfoModalStore/setInfoModal', {text: messageText});
            } else {
              if (this.isPhoneOption) {
                this.phoneNumberErrors.push(this.$gettext('Sorry, the phone/password you entered did not match our records.'));
                this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrorsName, errors: this.phoneNumberErrors});
              } else {
                this.emailErrors.push(this.$gettext('Sorry, the email/password you entered did not match our records.'));
                this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
              }
            }
          });
        }
      },
      trackSignInGA() {
        const option = this.addJobAfterSignIn ? 'directbook' : 'regular';
        this.$GAHelper.trackSignInGA(option);
      },
      trackSubmitGA(job = {}) {
        this.$GAHelper.trackCartEventsGA('add', this.$GADataConstructor.getBookProductObjGA());
        this.$GAHelper.trackSubmitGA(this.$GADataConstructor.getBookSubmitObjGA(job, 'direct_interpretation-loggedout'));
      },
      getRedirectLink({token, uid}) {
        const {country, lang: locale} = this.$route.params;
        const currentHost = window.location.hostname;
        const hostname = currentHost.indexOf('tikktalk') !== -1
          ? currentHost.replace('tikktalk.com', 'salita.no')
          : currentHost.replace('salita.no', 'tikktalk.com');
        return `https://${hostname}/${locale}/${country}/auth/sign_in?token=${token}&uid=${uid}`;
      },
      getDirectBookingRedirectLink(uid) {
        return this.$router.resolve(
          this.$makeRoute({
            name: 'BuyerPostInterpretation',
            query: {
              interpreterid: uid
            }
          })
        ).href;
      }
    },
    beforeMount() {
      this.$store.dispatch('FeatureFlagsStore/fetchFeatureFlags');
      if (this.$route.query.token && this.$route.query.uid) {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          if (this.$route.query.godmode) { this.$store.commit('TTAuthStore/setGodMode', true); }
          if (this.$route.query.punch_out_redirect_url) { this.$store.commit('TTAuthStore/setPunchout', this.$route.query.punch_out_redirect_url); }
          const params = {
            token: this.$route.query.token,
            uid: this.$route.query.uid
          };
          if (this.$route.query?.punch_out_redirect_url) {
            params.punchOutRedirectUrl = this.$route.query.punch_out_redirect_url;
          }
          this.$store.dispatch('TTAuthStore/logIn', params);
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$route.query.uid).then((userInfoObj) => {
            this.$store.commit('TTAuthStore/setAuthInfoToStorage', {token: this.$route.query.token, uid: this.$route.query.uid});
            if (this.$store.getters['UserInfoStore/userEntity'] === 'supplier' && this.$route.query.id) {
              this.$router.replace(this.$makeRoute({name: 'ServerOneAssignment', params: {id: this.$route.query.id}}));
            } else if (this.$route.query.id && (this.$route.query.edit || this.$route.query.edit_driving)) {
              const name = this.$route.query.edit ? 'BuyerEditInterpretation' : 'BuyerEditDrivingLicense';
              this.$router.replace(this.$makeRoute({name, params: {id: this.$route.query.id}}));
            } else if (this.addJobAfterSignIn && userInfoObj.role === 'rookie') {
              this.$store.dispatch('TTWizardStore/setRole', 'buyer').then(() => {
                this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
                  this.postJobAfterSignIn();
                });
              });
            } else if (this.addJobAfterSignIn) {
              this.postJobAfterSignIn();
            } else {
              window.location.href = 'sign_in';
            }
          });
        });
      }
    },
    mounted() {
      // validation of token
      if (this.$cookieManager.getCookie('authToken')) {
        this.$store.dispatch('TTAuthStore/validateToken').then(() => {});
      }
      if (this.$route.query.token && this.$route.query.uid) {
        this.progressActive = true;
      }
      if (this.$route.query.token && this.$route.query.uid && this.$route.query?.punch_out_redirect_url) {
        this.punchoutProgressActive = true;
      }
      if (this.$root.$defaultChannel && this.$webSocket) {
        this.$webSocket.remove(this.$webSocket.cable(), this.$root.$defaultChannel);
      }
    },
    beforeDestroy() {
      if (this.$isGodmode() && this.isLoggedIn && this.$route.query.id) {
        window.location.reload();
      }
      if (this.$cookieManager.getCookie('lastUsedType') === 'translation') this.$router.push({name: 'BuyerAllProjects'});
      else if (!this.$cookieManager.getCookie('lastUsedType')) {
        if (this.userBookedTranslationJobs && !this.userBookedInterpretationJobs) this.$router.push({name: 'BuyerAllProjects'});
      }
    }
  };
</script>
